<template>
  <div>
    <b-card>
      <div class="row mb-4">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'business-capacity' })" />
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <p class="h3">Modifier processus métier</p>
        </div>
      </div>

      <div class="shdow">
        <div class="row p-0 m-0">
          <div class="col-12">
            <p class="h4">Identité du processus</p>
          </div>
          <div class="col-12" style="flex: 1; margin-right: 20px">
            <b-form class="row">
              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Code processus:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input type="text" placeholder="" required></b-form-input>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Libellé processus:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-input type="text" placeholder="" required></b-form-input>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Patrimoine:</label>
                  </div>
                  <div class="col-md-8">
                    <select class="custom-select">
                      <option selected></option>
                    </select>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Capacité métier:</label>
                  </div>
                  <div class="col-md-8">
                    <select class="custom-select">
                      <option selected></option>
                    </select>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Business Owner:</label>
                  </div>
                  <div class="col-md-8">
                    <select class="custom-select">
                      <option selected></option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-12">
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Organisation responsable:</label>
                  </div>
                  <div class="col-md-8">
                    <select class="custom-select">
                      <option selected></option>
                      <option value="1">Steeve Ewore</option>
                    </select>
                  </div>
                </div>
                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label>Date de création:</label>
                  </div>
                  <div class="col-md-8">
                    <b-form-datepicker disabled :value="new Date()"></b-form-datepicker>
                  </div>
                </div>

                <div class="row my-3 align-items-center">
                  <div class="col-md-4">
                    <label class="mb-0">Taux de remplissage:</label>
                  </div>
                  <div class="col-md-8">
                    <b-progress :max="100" height="15px">
                      <b-progress-bar
                        :style="`background:${
                          70 > 20 && 70 <= 70
                            ? '#f5f245'
                            : 70 > 70
                            ? '#23d92c'
                            : '#f5ba45'
                        };font-size:8px;`"
                        :value="70"
                        :label="`${70}%`"
                      ></b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <div class="mt-5">
        <b-tabs
          :value="$store.state.tabs.activeTab"
          @changed="activeTab = $store.state.tabs.activeTab"
          @activate-tab="(newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)"
          class="tab-solid tab-solid-primary mt-3"
        >
          <b-tab title="Processus rattachés">
            <p class="text-secondary font-weight-bold">Processus mère</p>
            <job-process-processes />
            <p class="text-secondary font-weight-bold">Processus fille</p>
            <job-process-processes />
          </b-tab>

          <b-tab title="Applications">
            <job-process-application />
          </b-tab>

          <b-tab title="Objets manipulés">
            <job-process-business-object />
          </b-tab>
        </b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import JobProcessApplication from "./components/JobProcessApplication.vue";
import JobProcessBusinessObject from "./components/JobProcessBusinessObject.vue";
import JobProcessProcesses from "./components/JobProcessProcesses.vue";
export default {
  components: {
    ModalActions,
    JobProcessProcesses,
    JobProcessApplication,
    JobProcessBusinessObject,
  },
};
</script>

<style></style>
